import "./Terms.css";

const Terms = () => {
  return (
    <div className="Terms">
      <h1>利用規約</h1>
      <p>
        この利用規約（以下、「本規約」）は、Moca
        運営事務局（以下、「本提供者」）が提供するアプリ 「Moca |
        自分にメールを送るメモ帳」 （以下、「本サービス」といいます。）の
        <br />
        利用条件を定めるものです。お客様（以下、「ユーザー」）には、本規約に従って、本サービスをご利用いただきます。
      </p>
      <h2>第1条（適用）</h2>
      <p>
        本規約は、ユーザーと本提供者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
      </p>
      <h2>第2条（本サービスの提供の停止等）</h2>
      <p>
        本提供者は、以下のいずれかの理由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        <br />
        ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
        <br />
        ・地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
        <br />
        ・コンピュータまたは通信回線等が事故により停止した場合
        <br />
        ・その他、本提供者が本サービスの提供が困難と判断した場合
      </p>
      <p>
        本提供者は、本サービスの提供の停止または中断によりユーザーまたは第三者に生じた不利益または損害についても、一切の責任を負わないものとします。
      </p>
      <h2>第3条（本サービス内容の変更等）</h2>
      <p>
        本提供者は、ユーザーに通知することなく、本サービスの内容を変更できるものとします。
        <br></br>
        また、本提供者は本サービスの内容の変更によりユーザーまたは第三者に生じた不利益または損害についても、一切の責任を負わないものとします。
      </p>
      <h2>第4条（免責事項）</h2>
      <p>
        本提供者は、本サービスがユーザーの特定の目的に適合すること、期待する機能・価値・正確性・有用性を有すること、および不具合が生じないことについて何ら保証しません。
        <br />
        本提供者は、本サービスの利用によりユーザーが被った損害について一切の責任を負いません。
        <br />
        本サービスに発生した不具合、エラー、障害などにより本アプリケーションが利用できないことによって引き起こされた損害、
        <br />
        および本提供者またはユーザーに対するテロ、犯罪行為、不法行為等によって引き起こされた損害についても同様とします。
        <br />
        本提供者は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
      </p>
      <h2>第5条（利用規約の変更）</h2>
      <p>
        本提供者は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
        <br />
        また、本規約の変更後にユーザーが本サービスを利用した場合、当該ユーザーは変更後の規約に同意したものとみなします。
      </p>
      <h2>第6条（準拠法・裁判管轄）</h2>
      <p>
        本規約の解釈にあたっては、日本法を準拠法とします。
        <br />
        本サービスに関して紛争が生じた場合には、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <p>以上</p>
    </div>
  );
};

export default Terms;
