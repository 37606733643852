import wideImage from ".././my-app-wide-image.png";
import { ReactComponent as Badge } from ".././assets/Download_on_the_App_Store_Badge_JP_RGB_wht_100317.svg";

const Home = () => {
  return (
    <div>
      <h1>Fulltan - 時間割アプリ</h1>
      <img src={wideImage} alt="wideImage" />
      <p></p>
      <a
        href="https://apps.apple.com/jp/app/id1497457251"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Badge className="badge" />
      </a>
    </div>
  );
};

export default Home;
