import "./Portfolio.css";
import avator from "../.././ika_circle.png";
import fulltan from "../.././fulltan_image.png";
import { ReactComponent as Badge } from "../.././assets/Download_on_the_App_Store_Badge_JP_RGB_wht_100317.svg";

const Portfolio = () => {
  return (
    <div className="Portfolio">
      <p>Hiroki Ogata</p>
      <img src={avator} alt="avator" />
      <h1>⛄️WORKS⛄️</h1>
      <h2>Fulltan</h2>
      <p>
        学生時代に開発した時間割アプリ
        <br />
        企画・開発・デザイン・マネタイズをすべて1人で担当
        <br />
        個人で4年以上運用しており、総計30万ダウンロードを達成
      </p>
      <img src={fulltan} alt="fulltan" />
      <br />
      <br />
      <a
        href="https://apps.apple.com/jp/app/id1497457251"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Badge className="badge" />
      </a>
      <p>...他多数</p>
      <h1>⛰️EXPERIENCE⛰️</h1>
      <h2>株式会社サイバーエージェント</h2>
      <p>
        2024年4月 - 現在
        <br />
        iOS / Flutterエンジニア
      </p>
      <h2>楽天グループ株式会社（長期インターン）</h2>
      <p>
        2022年11月 - 2022年12月
        <br />
        エンジニア
      </p>
      <h2>株式会社VoiceUtopia（業務委託）</h2>
      <p>
        2022年5月 - 現在
        <br />
        Flutterエンジニア
      </p>
      <h2>株式会社CARCH（業務委託）</h2>
      <p>
        2022年1月 - 2022年2月
        <br />
        Flutterエンジニア
      </p>
      <h2>チームラボ株式会社（短期インターン）</h2>
      <p>
        2021年9月
        <br />
        iOSエンジニア
      </p>
      <h2>合同会社Moderation（長期インターン）</h2>
      <p>
        2021年6月 - 2022年10月
        <br />
        Flutterエンジニア
      </p>
      <h1>📚EDUCATION📚</h1>
      <h3>神戸大学大学院 工学研究科 機械工学専攻 卒業</h3>
      <h3>神戸大学 工学部 機械工学科 卒業</h3>
    </div>
  );
};

export default Portfolio;
