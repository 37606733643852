import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="Privacy">
      <h1>プライバシーポリシー</h1>
      <p>
        Moca
        運営事務局（以下、「本提供者」）は、本提供者の提供するサービス（以下、「本サービス」）におけるお客様（以下、「ユーザー」）の個人情報を含む利用者情報を、{" "}
        <br />
        以下の方針に基づいて取り扱います。
      </p>
      <h2>第1条（利用者情報の取得）</h2>
      <p>
        本提供者は、以下の利用者情報を当該ユーザーの同意を得た上で取得する場合があります。
        <br />
        ・広告識別子（IDFA） <br />
      </p>
      <p>
        また、本サービスでは、以下の情報が自動的に収集されます。 <br />
        ・アプリのクラッシュ情報
      </p>
      <p>
        本サービスは、広告配信のためにGoogle AdMobを使用しています。 <br />
        Google Admobにより、以下の利用者情報が自動的に収集されます。 <br />
        ・年齢 ・アプリのダウンロード元のストア ・アプリのバージョン ・国 <br />
        ・デバイスのブランド名 ・デバイスの種類（モバイル、タブレットなど）
        <br />
        ・デバイスの機種名（iPhone 14など） <br />
        ・ユーザーが最初にアプリを起動した時間 ・性別 ・ユーザーの興味や関心
        <br />
        ・デバイスの言語設定 ・新規ユーザー / 既存ユーザーの区別 <br />
        ・デバイスのOSのバージョン <br />
      </p>
      <h2>第2条（利用者情報の利用目的）</h2>
      <p>
        本提供者は、取得した利用者情報を以下の目的で利用します。 <br />
        ・本サービスの提供のため <br />
        ・本サービスのサービス向上を目的とした調査、分析、改善のため <br />
        ・ユーザーにより関連性の高い広告を配信するため <br />
        ・その他、上記の目的に付随する目的
      </p>
      <h2>第3条（利用者情報の第三者への提供）</h2>
      <p>
        本提供者は、利用者情報のうち個人情報について、個人情報保護法その他の法令に基づき開示が認められる場合を除き、
        <br />
        あらかじめユーザーの同意を得ないで、第三者に提供しません。ただし、次に掲げる場合はこの限りではありません。
        <br />
        ・利用目的のために必要な範囲において個人情報の取扱いの全部または一部を委託する場合
        <br />
        ・合併その他の事由による事業の承継に伴って個人情報が提供される場合
        <br />
        ・令状等の提出がなされた場合
        <br />
        ・その他、個人情報保護法その他の法令で認められる場合
      </p>
      <h2>第4条（プライバシーポリシーの改定手続）</h2>
      <p>
        第4条（プライバシーポリシーの改定手続）
        本提供者は、プライバシーポリシーの内容を改定した場合、改定後のプライバシーポリシーを本提供者ウェブサイト上に掲載します。
        <br />
        なお、法令上ユーザーの同意が必要となる内容の変更の場合には、本提供者所定の方法でユーザーの同意を得るものとします。
      </p>
      <h2>第5条（お問い合わせ窓口）</h2>
      <p>
        本サービスにおける利用者情報の取扱いに関するお問い合わせは下記の窓口までお願いします。
        <br />
        <a
          className="App-link"
          href="https://twitter.com/icoriha"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://twitter.com/icoriha
        </a>
      </p>
    </div>
  );
};

export default Privacy;
