import "./Support.css";

const Support = () => {
  return (
    <div className="Support">
      <h1>Support</h1>
      <p>
        製品について何かお困りのことがございましたら、こちらのメールアドレスにご連絡ください。
      </p>
      <p>Mail: contact.this.application@gmail.com</p>
      <p>※ お電話でのサポートには対応しておりません。ご了承ください。 </p>
    </div>
  );
};

export default Support;
