import "./App.css";
import Home from "./pages/Home";
import Support from "./pages/support/Support";
import FulltanTerms from "./pages/fulltan/Terms";
import FulltanPrivacy from "./pages/fulltan/Privacy";
import MocaTerms from "./pages/moca/Terms";
import MocaPrivacy from "./pages/moca/Privacy";
import Portfolio from "./pages/me/Portfolio";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/fulltan/terms" element={<FulltanTerms />} />
          <Route path="/fulltan/privacy" element={<FulltanPrivacy />} />
          <Route path="/moca/terms" element={<MocaTerms />} />
          <Route path="/moca/privacy" element={<MocaPrivacy />} />
          <Route path="/me" element={<Portfolio />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
